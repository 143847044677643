<template>
  <div class="wrap upFile">
    <van-form @submit="onSubmit">
      <van-field name="uploader">
        <template #input>
          <van-uploader
            :deletable="!isReadonly"
            v-model="fileList" 
            multiple=true
            :after-read="afterRead"
            @delete="handleRemove"
            :max-count="maxCount" />
        </template>
      </van-field>
      <div class="tips" v-if="imgtype=='com_account_base'||imgtype=='do_settle_base'">
        <p>温馨提示：</p>
        <p>1、上传附件为转账凭证</p>
      </div>
      <div class="tips" v-else-if="imgtype=='changecardf'">
        <p>温馨提示：</p>
        <p>1、上传附件为身份证正反面照片 </p>
        <p>2、上传银行卡正反面照片 </p>
      </div>
      <van-col span="20" offset="2" style="position:fixed;bottom:10px;">
        <van-button :disabled="isDisable" round block type="danger" native-type="submit">
        完成
        </van-button>
      </van-col>
    </van-form>
  </div>
</template>
<script>
import {upFile,getImgToken,upFileShow,upFileSave,getOpenId} from '@/server'
import { Toast} from 'vant';
import {setCookie,getCookie,domainUrl} from '@/lib/utils';
var deviceFileArr = [];
  export default {
  name:'upFile',
  data() {
    return {
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      contractNum: this.$route.query.conNo,
      urlList:[],
      isReadonly:this.$route.query.isReadonly,//数据是否已提交过
      imgtype:this.$route.query.imgtype,
      imgToken:'',
      fileList: [],
      imgUrl:[],
      maxCount:'5',
      isDisable:'',
    };
  },
  mounted(){
    sessionStorage.setItem('key', 0);
    let that = this;
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    // if(that.$route.path == '/modifyDebitCard'){
    //   window.history.pushState('forward', null, '/modifyDebitCard');
    // }
    // if(that.$route.path == '/earlySettlementApplication'){
    //   window.history.pushState('forward', null, '/earlySettlementApplication');
    // }
    // if(that.$route.path == '/corporateRepayment'){
    //   window.history.pushState('forward', null, '/corporateRepayment');
    // }
      if(sessionStorage.getItem('key') == 0){
        that.$store.commit('backFlagNum',that.contractNum);
        if(that.isReadonly==false){
          that.$store.commit('backFlag','1');
        }else{
          that.$store.commit('backFlag','0');
        }
      }
    
    }, false);
  }, 
  created(){
    //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
          setCookie('openId',res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/upFile' });
          }else{
            this.getDetail();
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/upFile');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
            setCookie('openId',res.openid);
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/upFile' });
            }else{
              this.getDetail();
            } 
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    }
  },
  methods: {
    getDetail(){
      // console.log(this.$store.state.imgUrlList)
      var urlList = this.urlList;
      if(this.isReadonly==true){//fileList:跟随所有数据已提交到服务器上的
        urlList = this.$store.state.fileList;
        this.maxCount = this.$store.state.fileList.length;
      }else{
        this.isReadonly = false;//imgUrlList:只是图片上传上去，没有跟随所有数据提交
        urlList = this.$store.state.imgUrlList=='' ? this.$store.state.fileList : this.$store.state.imgUrlList
      }
      if(urlList.length==0 || this.isReadonly==true){
        this.isDisable = true;
      }else{
        this.isDisable = false;
      }
      //获取token
      getImgToken({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.imgToken=res.img_token;
          localStorage.setItem("token", res.img_token);
          if(urlList.length!=0){
            urlList.map(item => {
              this.fileList = [...this.fileList,{url:item+'?Authorization='+res.img_token}];//图片展示
              this.imgUrl = [...this.imgUrl,item];
            });
          } 
        }
      })
    },
    afterRead(file,detail){
      this.isDisable = true;
      // deviceFile = [];
      let deviceFile = [];  //选择的图片数组
      let url = [];
      if(Array.isArray(file)){ //因为该组件单选是对象，多选是数组
        deviceFile = file
      }else{
        deviceFile.push(file)
      }
      deviceFile.map((item)=>{
        let formData = new FormData();
      //files是后台参数name字段对应值
        formData.append('files', item.file ,item.file.name);
        item.status = 'uploading';
        item.message = '上传中...';
        upFile(formData).then(res => {//将formDate文件上传，会返回图片地址
        if(res.status==200){
          this.isDisable = false
          for(let i in res.data){
            // url = [...url,res.data[i]];
            this.imgUrl=[...this.imgUrl,res.data[i]];
            // if(url.length == deviceFile.length){
            //   this.isDisable = false;
            // }
          }
          item.status = 'success';
          item.message = '上传成功';
          item.isDisableFlag = false;
        }
      });
      deviceFileArr= deviceFileArr.concat(deviceFile);
      })
    },
    handleRemove(file,detail){
      this.imgUrl = this.imgUrl.filter((item,i) => i !== detail.index);
    },
    onSubmit(values) {
    var everyResult = deviceFileArr.every( item => item.status == 'success' );
      if(!everyResult){
        Toast('图片正在上传，请稍后')
      }else{
        deviceFileArr = [];
        this.$store.commit('imgUrlList', this.imgUrl);
        this.$store.commit('backFlagNum', this.contractNum);
        this.$router.go(-1);
      }
      // this.imgUrl.map(item => {
      //     item.url= item.url+'?Authorization='+this.imgToken
      //   });
      // this.fileList = this.imgUrl
    },
  },
};
</script>
<style scoped>
.wrap{
    padding: 20px 0px;
    background-color: #fff;
  }
.tips{
  position: fixed;
  bottom: 100px;
  color:#808080;
  font-size:13px;
  margin-top: 20px;
}
.tips p{
    margin: 6px 15px;
}
</style>
<style>
.wrap .van-field__control{
  color: #666666 !important;
}
#app{
  background: rgba(0,0,0,0) !important;;
}

.wrap .van-uploader__preview{
  margin-bottom: 20px;
  margin-left: 1.5%;
  margin-right: 1.5%;
  width: 30%;
}
.wrap .van-uploader__preview-image {
  width: 100%;
  height: 110px;
  border-radius: 8px;
}
.wrap .van-uploader__upload{
  margin-bottom: 10px;;
  width: 30%;
  height: 110px;
  border-radius: 8px;
  margin-left: 1.5%;
  /* margin-left: 2.5%; */
}
.wrap .van-uploader{
  width: 100%;
}
.upFile .van-cell:after{
  border: 0;
}
</style>